import React from 'react'
import { Link } from 'react-router-dom'

const Fproduct = ({deta}) => {
 
  return (
    <div className='w-full h-96 border-2 border-zinc-200 rounded-xl overflow-hidden '>
        <img className='h-48 mb-5' src= {deta.image} alt='' />
        <div className='p-3'> 
        <h1 className='text-xl text-center mb-5'> {deta.Title}</h1>
        <p className='text-lg text-center mb-5'> {deta.Prise}</p>
        <div className='w-full flex justify-between'> 
            <Link to={deta.DemoLink} className='py-2 px-4 bg-oasim text-lg text-white'> Live Demo</Link>
            <Link
            to={`/demo/${deta.Title}`}
            state={{ alldeta: deta }}
            className='py-2 px-4 bg-oasim1 text-lg text-white'
          > 
             View Details</Link>

        </div>
        </div>
      
    </div>
  )
}

export default Fproduct
