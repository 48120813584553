import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle'
import Fproduct from '../product/Fproduct';
import { useDeta } from '../Context/ShareDeta';

const Agency = () => {
    const {Agency} = useDeta()
   
  return (
    <div className='w-90 m-auto h-max'>
        <h1 className='py-3 text-2xl uppercase'> Agency Website </h1>
<Swiper
 modules={[Autoplay]}
      spaceBetween={30}
      slidesPerView={4}
      loop = {true}
      hover={false}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
        hover:false
      }}
      
    >
     {Agency.map((items, index) => (
  <SwiperSlide key={index}>
    <Fproduct deta={items} />
  </SwiperSlide>
))}

     
     
     

    </Swiper>
</div>
  )
}

export default Agency
