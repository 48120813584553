import React from 'react'
import Markque from '../component/Markque'
import Header from '../Header'
import Fotter from '../Fotter'
import { Link, useLocation } from 'react-router-dom'

const SinglePage = () => {
    const location = useLocation();
    const { alldeta } = location.state || {};  // Fallback to an empty object if state is undefined
  
    if (!alldeta) {
      return <div>No product details available.</div>;
    }

    
  return (
  
    <>
    <Markque/>
    <Header/>
    <div className='w-full'> 
        <div className='w-90 m-auto  bg-oasim text-center text-xl py-3 text-white'> 
            <h1> <Link to="/"> Home </Link> /{alldeta.Title}</h1>

        </div>

        <div className='w-90 m-auto '> 
        <h1 className='text-4xl py-5'> {alldeta.Title}</h1>
        <div className='w-full flex m-auto py-5'> 
       
            <div className='w-8/12 px-5' >
           
            
            <img className='h-full' src={alldeta.image} alt='' />
           
             </div>
            <div className='w-4/12 border-2 border-oasim1'>
            <div className='w-90 m-auto flex text-3xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-2/3'> Reguler Price </h1>
                <h1 className='w-1/3'> {alldeta.Prise}  </h1>

            </div>
            <div className='w-90 m-auto  text-2xl gap-5 py-3 border-b-2 border-oasim1'> 
                <Link to= {`/demo/${alldeta.Title}/order`} className='w-full py-2 text-center block bg-oasim text-white'> Order Now </Link>
                <Link to={alldeta.DemoLink} className='w-full block text-center mt-3 py-2 bg-oasim1 text-white'> Live Preview</Link>

            </div>



            <div className='w-90 m-auto flex text-xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-1/2'> Last Update </h1>
                <h1 className='w-1/2'> {alldeta.LastUpdate}  </h1>

            </div>
            <div className='w-90 m-auto flex text-xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-1/2'> Release </h1>
               <h1 className='w-1/2'> {alldeta.Relese}  </h1>

            </div> 
            <div className='w-90 m-auto flex text-xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-1/2'> High Resolution </h1>
               <h1 className='w-1/2'> Yes  </h1>

            </div> 
            <div className='w-90 m-auto flex text-xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-1/2'> Compatible Browsers </h1>
               <h1 className='w-1/2'> Firefox, Safari, Opera, Chrome, Edge </h1>

            </div> 
            <div className='w-90 m-auto flex text-xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-1/2'> Language </h1>
               <h1 className='w-1/2'> {alldeta.Language} </h1>

            </div> 
            <div className='w-90 m-auto flex text-xl gap-5 py-3 border-b-2 border-oasim1'> 
                <h1 className='w-1/2'> Documentation </h1>
               <h1 className='w-1/2'>  Well </h1>

            </div> 
            <div className='w-90 m-auto flex text-xl gap-5 py-3'> 
                <h1 className='w-1/2'> Layout </h1>
               <h1 className='w-1/2'>  Responsive </h1>

            </div> 
            
             </div>

            
        </div>
        </div>

<div className='w-90 m-auto'> 
<h1 className="text-4xl text-center py-4"> ----------------Features---------------- </h1>
    <div className='w-full flex'> 
        <div className='w-1/2'> 
        <p className="text-2xl text-center py-5"> ===== Website Features =====</p>
        <ul className="custom-checklist text-xl">
  <li>Fully Responsive</li>
  <li>Fully Dynamic with Laravel Framework</li>
  <li>Full Custom Coding (SDLC)</li>
  <li>Home page will have featured products</li>
  <li>Category wise Product Show</li>
  <li>Brand wise Product show</li>
  <li>Product size, color, chart</li>
  <li>Landing Page</li>
  <li>Cart, Wishlist</li>
  <li>Email Notification Every Step</li>
  <li>Order Tracking (By Track Id)</li>
  <li>Product Sorting, Filter By Price</li>
  <li>Product Review</li>
  <li>Product Details With Multiple Images</li>
  <li>Product Youtube or Video Play</li>
  <li>Live Search</li>
  <li>Without login customer order place</li>
  <li>Easy Order Process</li>
  <li>Coupon Code</li>
  <li>Payment Cash on delivery, bKash, shurjopay (Api provided by client)</li>
  <li>Live Chat</li>
  <li>Customer Register</li>
  <li>Customer Login</li>
  <li>Forgot Password System</li>
  <li>Customer Dashboard</li>
  <li>Customer Profile</li>
  <li>Profile Edit</li>
  <li>Customer Order</li>
  <li>Customer Invoice</li>
  <li>Invoice Printing & Download</li>
  <li>Facebook Pixels</li>
  <li>GTM-4 Tracking</li>
  <li>Facebook Shop Connect (product auto upload)</li>
  <li>100% SEO Friendly</li>
</ul>


        </div>
        <div className='w-1/2'> 
        <p class="text-2xl text-center py-5"> ===== Admin Panel =====</p>
        <ul class="custom-checklist text-xl">
      <li>Multiple Admin Role Base Working</li>
      <li>Easy Order Manage</li>
      <li>Invoice Printing</li>
      <li>Category Manage</li>
      <li>Subcategory Manage</li>
      <li>Child Category Manage</li>
      <li>Brand Manage</li>
      <li>Color Manage</li>
      <li>Size Manage</li>
      <li>Product Manage</li>
      <li>Unlimited Brand, Size, Color Wise Product Upload</li>
      <li>Review Manage</li>
      <li>Unlimited Landing Page</li>
      <li>Multiple order invoice print</li>
      <li>Multiple order assign to user</li>
      <li>Multiple order status change</li>
      <li>Admin order edit</li>
      <li>Admin pos sales</li>
      <li>Monthly or Date wise Reports</li>
      <li>Unlimited Dynamic Page</li>
      <li>Facebook Pixels manage</li>
      <li>GTM-4 Tracking manage</li>
      <li>Courier API Connect (automatically)</li>
      <li>Payment Gateway Connect</li>
      <li>Sms Gateway Connect</li>
      <li>User & Permission</li>
      <li>Customer profile manage</li>
      <li>Banner Manage</li>
      <li>All Setting Change</li>
      <li>Contact manage</li>
      <li>Social media manage</li>
      <li>Shipping charge manage</li>
      <li>Coupon Code</li>
      <li>Social Media Linking</li>
      <li>Stock reports</li>
      <li>Sales reports</li>
      <li>Spam customer IP block</li>
  </ul>
  
        </div>

    </div>

</div>







    </div>
    <Fotter/>
    
    </>
  )
}

export default SinglePage
