import React from 'react'
import '../App.css'

const Markque = () => {
  return (
    <div className="marquee bg-oasim1 text-white py-3 text-lg">
      <div className="marquee-content">
        Offers :: Ecommerce Solution A-Z With Laravel <del>20000/-</del> 15000/- <span class="text-white"></span>
      </div>
      
    </div>
  )
}

export default Markque
