import React from 'react'
import { Link } from 'react-router-dom'

const Sliders = ({deta}) => {
  return (
    <div className='w-90 m-auto flex '>
        <div className='w-7/12  h-96  pr-5 flex flex-col  justify-center'> 
        <h1 className='text-3xl mb-5 uppercase text-white'>{deta.Title} </h1>
        <p className='text-xl capitalize text-white '> {deta.Dic} </p>
        <Link to="" className='text-xl py-2   mt-5 text-center bg-violet-600 w-3/12 text-white'> Veiw Details </Link>

        </div>
        <div className='w-5/12 h-96  flex items-center justify-center'> 
        <img src= {deta.image} alt='' /> 

        </div>
      
    </div>
  )
}

export default Sliders
