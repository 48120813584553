import React from 'react'
import Header from '../Header'
import Fotter from '../Fotter'

const Service = () => {
  return (
    <> 
    <Header/>

     <div>
      
      </div>
      <Fotter/>
    </>
   
  )
}

export default Service
