import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './img/logo.png'


const Header = () => {
  return (
    <div className='w-full border-b border-zinc-300'> 
    <div className=' w-90 flex justify-between py-3 m-auto '>  
    <div className='w-3/12 '> 
    <img className='w-2/3' src={Logo} alt='' />

    </div>
    <div className='w-9/12 flex gap-2  text-black justify-end uppercase'>
    {["home", "Our Services", "Our Team", "Our Hosting", "Our Blog", "Get in Touch"].map((item, index) => (
      <Link to={["/", "/service","/team", "/hosting", "/blog"][index]} key={index} className={`text-lg p-2 px-3  ${index===5 && "ml-32 bg-violet-500"} `}>{item}</Link>
    ))}
  </div>
  </div>
  </div>

  )
}

export default Header
