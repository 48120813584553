import React from 'react'
import '../App.css'
import { GiTrophyCup } from "react-icons/gi";
import Aboutt from '../img/about.png'

const About = () => {
  return (
    <div className='w-full bg-oasim2 py-5 '>
        <div className='w-90 flex m-auto '>
            <div className='w-1/2 flex items-center justify-center relative'> 
            <img className='w-9/12 h-7/12' src={Aboutt} alt='' />
            <div className='w-1/2 h-2/3 bg-oasim1 absolute left-0 p-5 text-white'> 
           <div className=' w-full text-8xl flex items-center justify-center '><GiTrophyCup /> </div> 
            <h1 className='text-4xl text-center'> Our Goal </h1>
            <p className='text-lg text-justify mt-3'> Rangcon try to identify key point of your business. We never compromised quality & better service.</p>


            </div>

            </div>
            <div className='w-1/2'> 
            <h1 className='text-4xl uppercase py-3 text-oasim5'>-- welcome to Rangcon</h1>
            <div className='w-full bg-white border-l-8 border-oasim text-3xl p-5 rounded-lg mb-3 capitalize'> 
            we are Rangcon, we always commited get best in web Service.
            </div>
            <p className='text-md'> Rangcon is fully Web Service company in the world. We are focus on Web Design & Development, Web Application Development, Custom Software Development, Graphics Design, Digital Marketing and Professional Web Training.</p>

            <ul className="checklist text-lg mt-5">
      <li>100% Dedicated Service</li>
      <li>24/7 Free Support</li>
      <li>Highly Experienced Team</li>
      <li>We Deliver What We Promise</li>
    </ul>

            </div>

        </div>
      
    </div>
  )
}

export default About
