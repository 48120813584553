import React from 'react'
import { Link } from 'react-router-dom'

const Fotter = () => {
  return (
    <>  
    <div className='w-full py-5 bg-black text-white'>
        <div className='w-90 flex gap-2 m-auto'> 
            <div className='w-1/4'> 
            <div className='text-xl w-90 uppercase py-3 border-b border-zinc-50 mb-5 text-oasim3'> 
                <h1>Corporate Office</h1>
            </div>
            <h1> Dhaka Bangladesh</h1>
            <p className='mb-10'> info@rangcon.com</p>

            <Link className='px-5 py-2 border-2 border-oasim1 hover:bg-oasim1 hover:text-white '> Get In Touch</Link>
            </div>
            <div className='w-1/6'> 
            <div className='text-xl w-90 uppercase py-3 border-b border-zinc-50 mb-5 text-oasim3'> 
                <h1>Quick Link </h1>
            </div>
                <ul className='text-lg'>
        <li>About Us</li>
        <li>Contact Us</li>
        <li>Services</li>
        <li>Blog</li>
        <li>FAQ</li>
        </ul>
            </div>
            <div className='w-5/12'> 
            <div className='text-xl w-90 uppercase py-3 border-b border-zinc-50 mb-5 text-oasim3'> 
                <h1>Our Hosting Services with domain</h1>
            </div>


            <ul className='text-lg'>
      <li>Shared Hosting</li>
      <li>WordPress Hosting</li>
      <li>Dedicated Hosting</li>
    </ul>
            </div>
            <div className='w-1/4'> 
            <div className='text-xl w-90 uppercase py-3 border-b border-zinc-50 mb-5 text-oasim3'> 
                <h1>Our web Services</h1>
            </div>

            <ul className='text-lg'>
      <li>Website Design And Development</li>
      <li>Complete Web Service</li>
      <li>Web Application Development</li>
      <li>Graphic Design</li>
    </ul>
            </div>
            
        </div>
      
    </div>

<div className='w-full py-3 bg-oasim4'> 
    <div className='w-90 text-white  text-center text-lg'> 
    Copyrights © 2019-2024 All Rights Reserved <Link to="https://rangcon.com" className='text-rose-600'> Rangcon </Link> 

    </div>

</div>
</>
  )
}

export default Fotter
