import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle'
import { useDeta } from '../Context/ShareDeta';
import Sliders from './Sliders';

const Slider = () => {
  const {Slider} = useDeta()

  return (
    <div className='w-full h-96 bg-gradient-to-r from-oasim to-oasim1 '>
      <Swiper
 modules={[Autoplay]}
      spaceBetween={20}
      slidesPerView={1}
      loop = {true}
      hover={false}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
        hover:false
      }}
      
    >
    {Slider.map((items, index)=>(
  <SwiperSlide key={index} >
  <Sliders deta={items}/>
 </SwiperSlide>

    ))}

  

     
     
     

    </Swiper>
    </div>
  )
}

export default Slider
