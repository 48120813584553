import React from 'react'
import Header from './Header'
import Slider from './component/Slider.js'
import Ecom from './component/Ecom.js'
import Agency from './component/Agency.js'
import { DetaProvider } from './Context/ShareDeta'
import Markque from './component/Markque.js'
import About from './component/About.js'
import Fotter from './Fotter.js'

const App = () => {
  return (
<>  
<DetaProvider>  
  <Markque/>
<Header/>
    <Slider/>
    <Ecom/>
    <Agency/>
    <About/>
    <Fotter/>

</DetaProvider>
   

    </>
  )
}

export default App
