import React, { createContext, useContext } from 'react';
import eyebox from '../img/eybox.PNG'
import airtopfly from '../img/airtopfly.PNG'
import rahatmart from '../img/rahatmart.PNG'
import kiksha from '../img/kiksha.PNG'
import ezipar from '../img/ezipar.PNG'
import spore from '../img/spore.PNG'

import domain from '../img/domain.png'
import graphic from '../img/graphic.png'
import webapp from '../img/webapp.png'
import webpic from '../img/webpic.png'

const ShareDeta = createContext();

export const useDeta = () => useContext(ShareDeta);

export const DetaProvider = ({ children }) => {

const Slider =[
{
image: webpic, Title:"best website design and development", Dic:"We build best website design and development. We are build your website totally row coding and high class."
},
{
image: domain, Title:"Domain and hosting service provider", Dic:"We also provide domain and hosting . We sell all kind of domain.,Like .com, .net, .org, .xyz, .com.bd etc domain and secure lightspeed server."
},
{
image: graphic, Title:"Ghaphic Design With Creative", Dic:"We Provide Good quality graphic design service. Logo Design, Banner Design, Business Card, Brochure Design, Clipping Path etc"
},
{
image: webapp, Title:"Web Application and Software Development", Dic:"Best Web Application and Software Development In Bangladesh. We build POS, Accounting &amp; Inventory, HR/Payroll, School Management"
},

]

const Ecom =[
  {
    image: eyebox , Title:"Eye boox  website", Prise:"30000TK", DemoLink:"https://eyeboox.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: rahatmart , Title:"Rahat Mart  website", Prise:"40000TK", DemoLink:"https://rahatmart.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: kiksha , Title:"Kiksha  website", Prise:"25000TK", DemoLink:"https://kikshaa.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: ezipar , Title:"Ezipar  website", Prise:"35000TK", DemoLink:"https://ezipar.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: spore , Title:"Resturent website", Prise:"50000TK", DemoLink:"https://saporeditaliastalbans.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  
  
 
]
const Agency =[
  {
    image: airtopfly , Title:"Airtop Fly  website", Prise:"35000TK", DemoLink:"https://airtopfly.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: airtopfly , Title:"Airtop Fly  website", Prise:"35000TK", DemoLink:"https://airtopfly.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: airtopfly , Title:"Airtop Fly  website", Prise:"35000TK", DemoLink:"https://airtopfly.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: airtopfly , Title:"Airtop Fly  website", Prise:"35000TK", DemoLink:"https://airtopfly.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },
  {
    image: airtopfly , Title:"Airtop Fly  website", Prise:"35000TK", DemoLink:"https://airtopfly.com", LastUpdate:"10/10/2024", Relese:"10/10/2024", Language:"Html5, css3, jQuery, Javascrip, Bootstrap, Ajax, Php, laravel, mySql", 
  },

 
]




return (
    <ShareDeta.Provider value={{Ecom, Agency, Slider}}>
      {children}
    </ShareDeta.Provider>
  );

};